@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;

  text-decoration: none;
  list-style: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
  letter-spacing: 1px;
  font-family: "Roboto", serif;
}
body{
  width:100vw;
  overflow: hidden;
  overflow-y:scroll;
  font-family: "Open Sans", sans-serif;  
}

h1{
    font-size:40px !important;
}

h3{
    font-size:30px !important;
}

p{
    font-size:17px !important;
    font-weight: normal;
}


.btn-solid{

    background-color: #e50202;
    border-radius: 5px;
    padding:15px;
    width:200px;
}

.btn-outline{
    background-color: transparent;
    border-radius: 5px;
    border: 1px solid #fff;
    padding:15px;
    width:200px;
}

.btn-contact-us{
    margin-left: 10px;
}

#navbar{
    background-color:#fff;
    width:100%;
    margin-left:0px;
    margin-right:0px;
    position:fixed;
    top:0px;
}

.banner-area {
  display: inline-block;  
  background: url('../../../assets/images/apisec-banner.png') no-repeat;
  background-size: cover;
  background-origin: border-box;
  min-height: 100vh;
  margin-bottom: 70px;
  color: white;
  display: block;
}

#navbar{
  width:100%;
}

.menu-item a{ 
    text-decoration: none;
    color:#616060 !important;    
}

.nav-logo {
  display: flex;
  justify-content: space-between;
  padding: 13px 4px 13px 4px;
}

.nav-logo button {
  margin-right: 20px;
}
#navbar {
  padding-top: 15px;
}

#navbar ul {
  display: none;
}

#navbar ul li a {
  color: #fff;
  font-weight: bold;
}

button {
  background-color: #a3c962;
  color: white;
  cursor: pointer;
  border: none;
  padding: 8px 20px;
  font-size: 15px;

  border-radius: 500px;
}

.nav-logo h1 a {
  margin-left: 20px;
  font-size: 27px;
  color: white;
  font-family: "Open Sans", sans-serif;
  display: initial;
  font-weight: bold;
  font-size: clamp(0.7rem, 0.5rem + 2vw, 27px);
  
}

.content {
  display: block;
  margin: 0 auto;
  text-align: center;
  position: relative;
  top: 20vh;
  padding-inline:10vw;
}

.content h1 {
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: clamp(0.7rem, 0.5rem + 2vw, 30px);
}

.content h3 {
  font-size: 20px;
  color: #fff;
  letter-spacing: 2px;
  font-weight: lighter;
}

.content p {
  color: #dadada;
  font-size: clamp(0.7rem, 0.5rem + 2vw, 16px);
  text-shadow: 2px 2px 8px #000;
  line-height: 30px;
  margin-bottom: 20px;
  text-align: inherit;
}

.content .btn-white {
  background-color: #fff;
  color: #a3c962;
  margin-right: 8px;
  margin-bottom: 95px;
  font-size: clamp(0.5rem, 0.5rem + 1vw, 16px);  
  padding: 0.5em 1.8em;
}

.content .btn-green {
  border-style: unset;
  color: #fff;
  border: 1px solid #fff;
  font-size: clamp(0.5rem, 0.5rem + 1vw, 16px);  
  padding: 0.5em 1.8em;
}

.content .btn-green:hover,
.content .btn-white:hover {
  background-color: #000;
  color: #fff;
  border: 1px solid #fff;
}

.center {
  justify-content: center;
  align-content: center;
  align-items: center;
}
.textcenter {
  text-align: center;
}
.textgreen {
  color: #a3c962 !important;
}
.context {
  line-height: 1.1rem;
}
.context h2 {
  margin-top: 1rem;
  margin-bottom: 1rem;
  font-size: 1.27rem;
  font-weight: 550;
}
.context p {
  font-size: 15px;
  color: #676662;
  letter-spacing: 1px;
  padding: 10px 0px;
  line-height: 28px;
  margin-bottom: 20px;
}
.context2 {
  display: block;
  width: 100%;
}
.fern {
  display: flex;
  width: 100%;
  justify-content: center;
}

.ferncontext {
  margin-top: 5%;
  margin-left: 3%;
}

.ferncontext h3 {
  margin-bottom: 15px;
}
.lightertext {
  font-weight: 100;
  font-size: 25px;
}
.ferncontext p {
  font-size: 1rem;
  color: #676662;
  letter-spacing: 1px;
  padding: 10px 0px;
  line-height: 28px;
  margin-bottom: 20px;
}

.progressbars {
  display: block;
}

.ninty {
  text-align: center;
  padding-bottom: 18px;
}

.eighty {
  padding-bottom: 18px;

  text-align: center;
}
.seventyfive {
  text-align: center;
}

.ninty h4,
.eighty h4,
.seventyfive h4 {
  margin-top: 1rem;
}

.progress {
  width: 7.5rem;
  height: 7.5rem;
  border: 2px solid #049dff;
  border-radius: 50%;
  transform: rotate(135deg);
}
.progress-left {
  width: 90px;
  height: 90px;
  border: 1px solid #b1b1b1;
  margin: 0 auto;
  border-radius: 50%;
  position: relative;
  top: 0.75rem;
  z-index: 1;
  transform: rotate(-135deg);
}
.progress-right {
  width: 40%;
  height: 25%;
  background: #fff;    
}
.progress-value {
  position: relative;
  top: 1.95rem;
  left: 0.25rem;
  font-size: 30px;
  color: #049dff;
}
.progress-status {
  display: flex;
  justify-content: center;
}

.progress-green {
  width: 7.5rem;
  height: 7.5rem;
  border: 2px solid #1abc9c;
  border-radius: 50%;
  transform: rotate(112deg);
}

.progress-left-green {
  width: 90px;
  height: 90px;
  border: 1px solid #b1b1b1;
  margin: 0 auto;
  border-radius: 50%;
  position: relative;
  top: 0.75rem;
  z-index: 1;
  transform: rotate(-112deg);
}

.progress-right-green {
  width: 50%;
  height: 34%;
  background: #fff;  
}

.progress-value-green {
  position: relative;
  top: 1.95rem;
  left: 0.25rem;
  font-size: 30px;
  color: #1abc9c;
}

.progress-status-green {
  display: flex;
  justify-content: center;
}

.progress-golden {
  width: 7.5rem;
  height: 7.5rem;
  border: 2px solid #fdba04;
  border-radius: 50%;
  transform: rotate(100deg);
}

.progress-left-golden {
  width: 90px;
  height: 90px;
  border: 1px solid #b1b1b1;
  margin: 0 auto;
  border-radius: 50%;
  position: relative;
  top: 0.75rem;
  z-index: 1;
  transform: rotate(-100deg);
}

.progress-right-golden {
  width: 70%;
  height: 37%;
  background: #fff;  
}

.progress-value-golden {
  position: relative;
  top: 1.95rem;
  left: 0.25rem;
  font-size: 30px;
  color: #fdba04;
}

.progress-status-golden {
  display: flex;
  justify-content: center;
}


.forest {
  background: url(https://raw.githubusercontent.com/Nis-chal/web-dev/main/images/s1.jpg);
  padding-bottom: 4%;
}
.forest--text {
  display: block;

  text-align: center;
  padding: 10px;
}
.forest--text {
  color: #fff;
}

.forest__heading > h3 {
  color: #a3c962;
}

.forest__heading > h1 {
  color: #fff;
  font-weight: lighter;
}
.forest__paragraph > p {
  line-height: 30px;
  font-weight: lighter;
}

.box {
  display: block;
  text-align: center;
  background: #fff;
  margin: 3%;
  padding: 5%;
}
.box span > i {
  font-size: 1.6rem;
}
.box > p {
  letter-spacing: 0.4px;
  line-height: 32px;
  color: #676662;
}
.box > h2 {
  color: #a3c962;
  padding: 10px;
}

/* about about about about about */

.about {
  padding-bottom: 4%;
}

.about--text {
  display: block;

  text-align: center;
  padding: 10px;
  color: #fff;
}

.about__heading > h3 {
  color: #a3c962;
}

.about__heading > h1 {
  color: #000;
  font-weight: lighter;
}

.about__paragraph > p {
  line-height: 30px;
  font-weight: lighter;
  color: #676662;
}

.about__container {
  display: flex;
  margin: 15px;
  gap: 5%;
}

.about--imgcolumn1 {
  width: 45.5%;
}
.about__innerimage {
  width: 100%;
}

.about__outerimage {
  width: 100%;
}

.about--imgcolumn2 {
  width: 45.5%;
}

.about--imgcolumn2 span > img {
  width: 100%;
}

.about__imgrow2 {
  width: 100%;
  font-size: 0;
  margin-bottom: 0.5rem;
}

@media screen and (min-width: 540px) {
  .ferncontext {
    width: 79%;
  }
  .progressbars {
    display: flex;
    justify-content: space-around;
  }
  /* forest forest forest  */
  .forest--boxes {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    margin: 0 auto;

    justify-content: center;
  }

  .box {
    width: 40%;
    padding: 4%;

    margin: 10px;
  }
  /* about about about about about*/
}

@media screen and (min-width: 1000px) {

  .container {
    width: 100vw;
  }   

  .nav-logo button {
    display: none;
  }

  #navbar {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  #navbar ul {
    display: flex;
    margin-right: 3rem;
  }
  #navbar ul li {
    padding: 0 1rem;
  }

  .context2 {
    display: block;
    width: 58.85%;
  }
  .progressbars {
    width: 67%;
  }

  .container2 {
    display: flex;
    align-items: center;
    margin-left:10%;
    margin-right:10%;
  }
  .fern {
    width: 50%;
    height: max-content;
  }
  .fern img {
    margin-right: auto;
    margin-left: auto;
    width: 60%;
    height: 100%;
    object-fit: cover;
  }

  /* forest forest forest forest forest forest */

  .forest {
    height: 100%;
  }
  .forest--text {
    display: flex;
    align-items: center;
    padding-top: 25px;
  }
  .forest__paragraph > p {
    font-size: 14px;
    letter-spacing: 0.5px;
  }
  .forest__paragraph {
    text-align: end;
    width: 35%;
    margin-right: 25px;
    margin-left: 14%;
  }

  .forest--boxes {
    width: 90%;
    display: flex;
    flex-wrap: wrap;
    margin: 0 auto;

    justify-content: center;
  }

  .box {
    width: 350px;
    margin: 10px;
  }
}

/* about media about media about media about media about media about media about media */

@media screen and (min-width: 700px) {
  .about__container {
    margin-left: 10%;
    margin-right: 10%;
  }

  .about--imgcolumn1 {
    width: 100%;
    margin-left: 5%;
  }
  .about__imgrow1 {
    display: flex;
    width: 100%;
  }

  .about--imgcolumn2 {
    width: 100%;
  }
  .about__imgrow2 {
    display: flex;
    width: 100%;
    justify-content: space-between;
  }

  .about--imgcolumn2 span > img {
    width: 100%;
  }
}


ul {
    margin: 0px;
    padding: 0px;
}
.footer-section {
  background: #151414;
  position: relative;
}
.footer-cta {
  border-bottom: 1px solid #373636;
}
.single-cta i {
  color: #e50202;
  font-size: 30px;
  float: left;
  margin-top: 8px;
}
.cta-text {
  padding-left: 15px;
  display: inline-block;
}
.cta-text h4 {
  color: #fff;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 2px;
}
.cta-text span {
  color: #757575;
  font-size: 15px;
}
.footer-content {
  position: relative;
  z-index: 2;
}
.footer-pattern img {
  position: absolute;
  top: 0;
  left: 0;
  height: 330px;
  background-size: cover;
  background-position: 100% 100%;
}
.footer-logo {
  margin-bottom: 30px;
}
.footer-logo img {
    max-width: 200px;
}
.footer-text p {
  margin-bottom: 14px;
  font-size: 14px;
      color: #7e7e7e;
  line-height: 28px;
}
.footer-social-icon span {
  color: #fff;
  display: block;
  font-size: 20px;
  font-weight: 700;
  font-family: 'Poppins', sans-serif;
  margin-bottom: 20px;
}
.footer-social-icon a {
  color: #fff;
  font-size: 16px;
  margin-right: 15px;
}
.footer-social-icon i {
  height: 40px;
  width: 40px;
  text-align: center;
  line-height: 38px;
  border-radius: 50%;
}
.facebook-bg{
  background: #3B5998;
}
.twitter-bg{
  background: #55ACEE;
}
.linkedin-bg{
  background: #0a66c2;
}

.footer-widget-heading h3 {
  color: #fff;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 40px;
  position: relative;
}
.footer-widget-heading h3::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: -15px;
  height: 2px;
  width: 50px;
  background: #e50202;
}
.footer-widget ul li {
  display: inline-block;
  float: left;
  width: 50%;
  margin-bottom: 12px;
}
.footer-widget ul li a:hover{
  color: #e50202;
}
.footer-widget ul li a {
  color: #878787;
  text-transform: capitalize;
}
.subscribe-form {
  position: relative;
  overflow: hidden;
}
.subscribe-form input {
  width: 100%;
  padding: 14px 28px;
  background: #2E2E2E;
  border: 0px solid #2E2E2E;
  color: #fff;
}
.subscribe-form button {
    position: absolute;
    right: 0;
    background: #e50202;
    padding: 13px 20px;
    border: 1px solid #e50202;
    top: 0;
}
.subscribe-form button i {
  color: #fff;
  font-size: 22px;
  transform: rotate(-6deg);
}
.copyright-area{
  background: #202020;
  padding: 25px 0;
}
.copyright-text p {
  margin: 0;
  font-size: 14px;
  color: #878787;
}
.copyright-text p a{
  color: #e50202;
}
.footer-menu li {
  display: inline-block;
  margin-left: 20px;
}
.footer-menu li:hover a{
  color: #e50202;
}
.footer-menu li a {
  font-size: 14px;
  color: #878787;
}


.copyright-text a{
    text-decoration: none;
    font-weight: bold;
}

.content p{
    margin-left:15%;
    margin-right:15%
}

.footer-widget a{
    text-decoration: none;
}

.login-page-card-heading{
    color:#616060 !important
}

footer{
  padding:50px;
}

input{
  outline: none;
}


@media (max-width: 768px) {

  .features-section{    
      margin-top:300px;
  }

  .banner-area{
    height:auto;
  }

  .content p{
    margin-left:10px;
    margin-right:10px;
  }

  .btn-contact-us{
    margin-left: 0px;
    margin-top:10px;
  }

  footer{
    padding:10px;
  }

  .find-us{
    margin-left: 9px;
  }

  .single-cta{
    margin-top: 5px;;
  }

  .useful-links{
    margin-top: 40px;
  }

  .follow-us{
    margin-top: 30px;
  }

  .fern img{
    width:300px;
    height:auto;
  }

  .context2{
    padding:30px;
  }

}


